<template>
    <div class="i-surveys-result-list-item">
      <div class="item-main">
        <div class="title">
          <div class="status" :class="reviewStatusTag">{{ reviewStatus }}</div>
          <div class="title--inner van-ellipsis">{{ item.surveyFormDTO.title }}</div>
        </div>
        <div class="desc">
          <div class="date">{{ updateDate }}</div>
          <div class="recognize-code" v-if="item.recognizeCode && item.reviewStatus"><span class="line">|</span>识别码：{{ item.recognizeCode }}</div>
        </div>
        <div class="error-message" v-if="item.reviewStatus == 3">不通过原因：{{ item.reviewComment }}</div>
      </div>
      <div class="item-action van-hairline--bottom">
        <van-button v-if="item.reviewStatus == 2 && item.surveyFormDTO.isConvertPpt == 1" @click="handleGeneratePPT">生成PPT</van-button>
        <van-button v-if="item.reviewStatus == 4 && item.surveyFormDTO.isConvertPpt == 1" @click="handleGeneratePPT">生成PPT</van-button>
        <van-button type="primary" v-if="item.reviewStatus == 3" @click="handleClick">修改</van-button>
        <van-button type="primary" v-if="item.reviewStatus == 0" @click="handleClick">编辑</van-button>
        <van-button v-if="'1,2,4'.includes(item.reviewStatus)" @click="handleClick">查看内容</van-button>
        <van-button v-if="'2,4'.includes(item.reviewStatus) && item.surveyFormDTO.followSetting" @click="clickFollow">随访<span class="red-drop" v-if="item.redDot"></span></van-button>
      </div>
      <GeneratePptDialog ref="refGeneratePptDialog" />
    </div>
</template>

<script>
import GeneratePptDialog from './generate-ppt-dialog.vue'
export default {
    components: { GeneratePptDialog },
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        statusMap () {
            return this.$status.iSurveys
        },
        reviewStatusTag () {
            return this.statusMap.reviewStatusTag[this.item.reviewStatus]
        },
        reviewStatus () {
            return this.statusMap.reviewStatus[this.item.reviewStatus]
        },
        handleActionText () {
            if ([0, 3].includes(this.item.reviewStatus)) {
                return '编 辑'
            }
            return '查 看'
        },
        updateDate () {
            return this.item.updateTime.split(' ')[0].split('-').join('·')
        }
    },
    methods: {
        handleClick () {
            this.$emit('click', {
                value: this.item
            })
        },
        clickFollow () {
          this.$router.push(`/i-surveys/follow/list/${this.item.id}`)
        },
        handleGeneratePPT () {
          this.$refs.refGeneratePptDialog.init(this.item)
        }
    }
}
</script>
