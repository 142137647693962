<template>
  <div class="i-surveys-form">
    <div class="i-surveys-form-message" v-if="detail.reviewStatus == 3 && detail.reviewComment">
      <van-notice-bar color="#FA5151" background="#FDEAEA" left-icon="info" wrapable :scrollable="false" :text="`驳回原因：${detail.reviewComment}`" />
      <div class="is_show_fail_topic">
        <van-checkbox v-model="isShowFailTopic" icon-size="16px" checked-color="var(--color-primary)">仅展示被驳回题目</van-checkbox>
      </div>
    </div>
    <div class="i-surveys-form-main">
      <van-form ref="vanForm" @submit="clickSubmit" scroll-to-error>
        <div v-for="(item, index) in exam" :key="item.locationKey">
          <component
			  :ref="`topic_${item.locationKey}`"
			  :is="getComponentName(item.questionType)"
			  v-model="item.value"
			  :getComponentName="getComponentName"
			  :getComponentAttr="getComponentAttr"
			  v-bind="getComponentAttr(item, index)"
			  v-if="checkShowMethods(item, index, true)"
			  :itemIndex="index"
			  :checkShowMethods="checkShowMethods"
			  :mustAnswer="item.mustAnswer"
			  :questionType="item.questionType"
			  @scrollExam="handleScrollExam"
			  @updateOptions="updateOptions"
			  :needCount="item.needCount"
			  @sumBlur="handlerSumBlur"
		  >
          </component>
        </div>
        <div class="placeholder"></div>
        <base-footer v-if="$route.query.formType !== 'preview'" background-color="#fff">
          <van-row :gutter="20">
            <van-col :span="12">
              <van-button block round native-type="button" @click="clickTemporaryStorage">暂 存</van-button>
            </van-col>
            <van-col :span="12">
              <van-button type="primary" block round native-type="submit">提 交</van-button>
            </van-col>
          </van-row>
        </base-footer>
      </van-form>
    </div>
  </div>
</template>

<script>
import FormInput from "./components/form-input.vue";
import FormTextarea from "./components/form-textarea.vue";
import FormRadio from "./components/form-radio.vue";
import FormCheckbox from "./components/form-checkbox.vue";
import FormUpload from "./components/form-upload.vue";
import FormDate from "./components/form-date.vue";
import FormTitle from "./components/form-title.vue";
import FormSubTitle from "./components/form-sub-title.vue";
import FormBmi from "./components/form-bmi.vue";
import FormSum from "./components/form-sum.vue";
import FormSbm from './components/form-sbm.vue';
import DmActivityMixin from '@/mixins/dm-activity'
import { mapMutations } from "vuex";

export default {
  name: "surveysForm",
  mixins: [DmActivityMixin],
  components: {
    FormInput,
    FormTextarea,
    FormRadio,
    FormCheckbox,
    FormUpload,
    FormDate,
    FormTitle,
    FormSubTitle,
    FormBmi,
    FormSum,
    FormSbm
  },
  data: () => ({
    loading: true,
    uploadFileList: [],
    detail: {},
    exam: [],
    // 是否只展示审核失败的题
    isShowFailTopic: false,
    // 选择结束时，题的索引
    endTopicIndex: 999999999,
    isJump: "",
    jumpStartIndex: 0,
    jumpEndIndex: 0,
    currentIndex: "",
    isShow: false,
    indexValue: "",
  }),
  computed: {
    saveUploadApi() {
      if (this.$route.query.isFollow) {
        return this.$api.surveys.followSubmitForm
      }
      if (this.$route.query.formType === "edit") {
        return this.$api.surveys.asnwerUpdate;
      }
      return this.$api.surveys.submitAnswer;
    },
  },
  mounted() {
    if (this.$route.query.isFollow) {
      this.getFollowDetail();
    } else if (this.$route.query.formType) {
      this.getAnswerDetail();
    } else {
      this.getDetail();
    }
  },
  watch: {},
  methods: {
    ...mapMutations("surveys", ["SET_PAGE_TITLE"]),
    async getFollowDetail () {
      try {
        this.loading = true;
        const res = await this.$api.surveys.followDetail(
          this.$route.params.id
        );
        if (!res.code) {
          this.detail = res.data;
          if (this.$route.query.formType) {
            this.answerDetailFormat(res.data.answerJson, res.data.followDTO.followQuestionContent)
          } else {
            this.formDetailFormat(res.data.followDTO.followQuestionContent)
          }
          console.log('this.exam ===>', this.exam)
          this.SET_PAGE_TITLE(res.data.followDTO.followName);
        }
        this.loading = false;
      } catch (err) {
        console.log("表单解析失败===>", err);
        this.loading = false;
      }
    },
    // 获取表单详情
    async getDetail() {
      try {
        this.loading = true;
        const res = await this.$api.surveys.formDetail(
          this.$route.params.id
        );
        if (!res.code) {
          this.detail = res.data;
          this.formDetailFormat(res.data.formJson)
          console.log('this.exam ===>', this.exam)
          this.SET_PAGE_TITLE(res.data.title);
        }
        this.loading = false;
      } catch (err) {
        console.log("表单解析失败===>", err);
        this.loading = false;
      }
    },
    // 获取作答详情
    async getAnswerDetail() {
      try {
        this.loading = true;
        const res = await this.$api.surveys.asnwerDetail(
          this.$route.params.id
        );
        if (!res.code) {
          this.detail = res.data;
          if (!res.data.copyFormJson) return this.$toast('问卷列表初始化失败')
          this.answerDetailFormat(res.data.answerJson, res.data.copyFormJson)
          this.SET_PAGE_TITLE(res.data.surveyFormDTO.title);
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    formDetailFormat (formJson) {
      const exam = JSON.parse(formJson);
      this.exam = exam.map((item) => ({
        ...item,
        value: "12,31,32".includes(item.questionType) ? [] : "",
      }));
    },
    answerDetailFormat (answerJson, formJson) {
      const exam = JSON.parse(answerJson);
      const originExam = JSON.parse(formJson);
      const answerExamKeys = exam.map(item => item.locationKey);

      try {
        this.exam = originExam.map((item, index) => {
          if (answerExamKeys.includes(item.locationKey)) {
            const topic = exam.find(val => val.locationKey == item.locationKey);
            if (topic.questionType == 31 || topic.questionType == 32) {
              topic.value = (topic.value || []).filter(val => val.status == 'done')
            }
            if (topic.questionType == 11) {
              if (topic.value || topic.value === 0) {
                const questionJump = topic.optionList[topic.value].questionJump
                if (questionJump && questionJump.locationKey == -1) {
                  this.endTopicIndex = index
                }
              }
            }
            if (topic.questionType == 11 || topic.questionType == 12) {
              topic.optionList.forEach(item => {
                if (item.ext && item.ext.indexValue) {
                  if (!item.ext.inputValue && item.ext.type.includes('input')) {
                    item.ext.inputValue = item.ext.indexValue || ''
                  }
                  if (!(item.ext.fileUrl || []).length && item.ext.type.includes('img')) {
                    item.ext.fileUrl = item.ext.indexValue || []
                  }
                }
              })
            }
            return topic
          }
          return item
        })
      } catch (err) {
        console.log(err)
      }

      console.log('this.exam ===>', this.exam)
      this.handleHideTopic();
    },
    getComponentName(val) {
      return (
        {
          11: "FormRadio",
          12: "FormCheckbox",
          22: "FormTextarea",
          31: "FormUpload",
          32: "FormUpload",
          41: "FormDate",
          61: "FormTitle",
          62: "FormSubTitle",
          101: "FormBmi",
          102: 'FormSum',
          110: 'FormSbm'
        }[val] || "FormInput"
      );
    },

    getComponentAttr(item, index, itemType) {
      const attrs = {
        label: `${item.name}`,
        index: index,
        itemKey: item.locationKey,
        layoutType: item.layoutType,
        placeholder: item.remark || `请输入内容`,
        endTopicIndex: this.endTopicIndex,
        isShowFailTopic: this.isShowFailTopic,
        auditStatus: this.detail.reviewStatus,
        rejectSetting: item.rejectSetting,
        exam: this.exam
      };


      if ("11,12,21,101,102,110".includes(item.questionType)) {
        attrs["options"] = item.optionList;

        if ("21".includes(item.questionType)) {
          attrs["settingContent"] = item.settingContent || {};
          attrs["settingNumber"] = item.settingNumber || {};
          if (itemType == 'sbm') {
            attrs.options.forEach(item => {
              if (!item.settingContent) {
                item["settingContent"] = {
                  contentRestrictions: 1,
                  contentType: 'letter',
                  min: 1,
                  max: 99999
                }
              }
            })
          }
        }
      }
      if ("61,62".includes(item.questionType)) {
        attrs["fileInfo"] = {
          needFile: item.needFile,
          fileType: item.fileType,
          fileList: item.fileList
        }
      }
      if ("11,12,41".includes(item.questionType)) {
        attrs["placeholder"] = item.remark || `请选择答案`;

        if ("41".includes(item.questionType)) {
          attrs["placeholder"] = item.remark || `请选择日期`;
          attrs["dateFormat"] = item.showDateFormat;
          attrs["timeSetting"] = item.timeSetting;
          attrs["timeLimitSetting"] = item.timeLimitSetting;
          attrs["timeDependSetting"] = item.timeDependSetting;
        }
        if ("12".includes(item.questionType)) {
          attrs["isManualSort"] = item.isManualSort;
        }
        if ("11,12".includes(item.questionType)) {
          attrs["fileType"] = item.fileType;
          attrs["fileList"] = item.fileList;
          attrs["optionType"] = item.optionType;
          attrs["options"].forEach(val => {
            if (val.ext && typeof val.ext === 'string') {
              val.ext = JSON.parse(val.ext)
            }
          })
        }
      }
      if ("22".includes(item.questionType)) {
        attrs["maxLength"] =
          typeof item.optionList[0].suffixName === "number"
            ? item.optionList[0].suffixName
            : 1000;
      }
      if ("31,32".includes(item.questionType)) {
        attrs["placeholder"] = item.remark || `请上传图片`;
        attrs["limit"] = item.verifyMax;
        attrs["ocr"] = item.ocrText;
        attrs["accept"] =
          item.isImgOrVideo == 1
            ? "*"
            : item.uploadImg && item.uploadVideo
              ? "*"
              : item.uploadImg
                ? "image/*"
                : "video/avi,video/mp4,video/mov,video/muv,video/AVI,video/MP4,video/MOV,video/MUV";
      }

      if (item.mustAnswer) {
        attrs["rules"] = [
          { required: true, message: attrs.placeholder },
        ];
      }

      return attrs;
    },

    // 特殊字段的特殊校验
    verification() {
      for (let i = 0; i < this.exam.length; i++) {
        const item = this.exam[i];
        // 图片题校验
        if ((item.questionType == 31 || item.questionType == 32) && item.value) {
          const isExistUploading = item.value.some(val => val.status != 'done')
          if (isExistUploading) {
            this.$toast("请等待文件上传完成");
            return false
          }
        }
      }
      return true
    },
    // 点击提交
    async clickSubmit(status) {
      this.handlerSumBlur()
      if (!this.verification()) return

      try {
        let exam = this.exam;
        const answerJsonCopy = JSON.stringify(this.exam);
        if (
          this.endTopicIndex > -1 &&
          this.endTopicIndex <= this.exam.length &&
          status
        ) {
          exam = this.exam.slice(0, this.endTopicIndex + 1)
        }
        if (status) {
          // 单独处理隐藏条件
          exam = exam.filter((item, index) => this.checkShowMethods(item, index));
        }
        if (!(exam || []).length) {
          this.$toast(`网络繁忙，请刷新后重试`);
          return
        }

        if (this.$route.query.isFollow) {
          await this.submitFollowForm(answerJsonCopy, exam, status);
          return
        }
        this.checkIsDialogConfirm(
          this.$route.query.activityId,
          { 1: 'questionnaire', 2: 'clinical' }[this.$route.query.type],
          () => this.submitHandle(answerJsonCopy, exam, status)
        )
      } catch (err) {
        this.loading = false;
      }
    },
    async submitHandle (answerJsonCopy, exam, status) {
      try {
        // 获取识别码
        const recognizeCode = this.getExamRecognizeCode(exam)
        let params = {
          answerJsonCopy,
          recognizeCode,
          answerJson: JSON.stringify(exam),
          surveyId: this.detail.surveyId,
          formId: this.detail.id,
          reviewStatus: status ? 1 : 0,
          budgetId: this.detail.budgetId || this.$route.query.budgetId,
          budgetSettingId: this.detail.budgetSettingId || this.$route.query.budgetSettingId,
          taskId: this.$route.query.taskId,
        };
        if (this.$route.query.formType === "edit") {
          params["id"] = this.$route.params.id;
          (params["formId"] = this.detail.formId),
            (params["taskId"] = this.detail.taskId);
        }
        if (this.loading) return
        this.loading = true;
        const res = await this.saveUploadApi(params);
        this.loading = false;
        if (!res.code) {
          this.$router.replace({
            path: "/i-surveys/result",
            query: {
              status: status == 0 ? 'draft' : 'submit',
              type: this.$route.query.type,
              surveyId: this.detail.surveyId,
            },
          });
        }
      } catch (err) {
        this.loading = false;
      }
    },

    // 随访表单提交
    async submitFollowForm (answerJsonCopy, exam, reviewStatus) {
      try {
        const recognizeCode = this.getExamRecognizeCode(exam)
        const params = {
          answerJsonCopy,
          recognizeCode,
          answerId: this.detail.answerId,
          answerJson: JSON.stringify(exam),
          doctorId: this.detail.doctorId,
          followId: this.$route.params.followId,
          id: this.$route.params.id,
          reviewStatus: reviewStatus ? 1 : 4
        }
        const res = await this.saveUploadApi(params);
        if (!res.code) {
          this.$router.go(-1);
        }
        this.loading = false;
      } catch(err){
        console.log(err);
        this.loading = false;
      }
    },
    // 获取识别码拼接成的识别码
    getExamRecognizeCode (l = []) {
      const t = l.find(v => v.questionType == 110)
      return t ? this.$refs[`topic_${t.locationKey}`][0].getExamRecognizeCode : ''
    },
    // 点击暂存
    clickTemporaryStorage() {
      this.handlerSumBlur()
      // console.log(this.exam);
      this.clickSubmit(0)
    },
    // 退出拦截
    exitIntercept() {
      if (this.$route.query.formType === "preview") {
        this.$router.go(-1);
        return;
      }
      const _this = this;
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认退出调研？",
          confirmButtonText: "取 消",
          cancelButtonText: "确认并保存",
        })
        .then(() => {
          _this.$router.go(-1);
        })
        .catch(() => {
          _this.clickSubmit(0);
        });
    },
    // 处理隐藏题的逻辑
    handleHideTopicCallback(currentIndex, targetIndex) {
      this.exam.forEach((item, index) => {
        if (currentIndex > targetIndex) {
          if (index > targetIndex) {
            item.hideTopic = index < currentIndex;
          }
        }
        if (currentIndex < targetIndex) {
          if (index > currentIndex) {
            item.hideTopic = index < targetIndex;
          }
        }
      });
    },
    // 处理隐藏题的逻辑
    handleHideTopic() {
      this.exam.forEach((item) => {
        item.hideTopic = false;
      });

      this.exam.forEach((item, index) => {
        // 只有选择题存在跳题逻辑
        if (item.value || item.value === 0) {
          if (item.questionType == 11) {
            const questionJump = item.optionList[item.value].questionJump;
            if (questionJump) {
              const targetIndex = this.exam.findIndex((val) => questionJump.locationKey == val.locationKey);
              targetIndex > -1 && this.handleHideTopicCallback(index, targetIndex);
            }
          }
          // if (item.questionType == 12) {
          // 	(item.value || []).forEach((answerIndex) => {
          // 		const questionJump = item.optionList[answerIndex].questionJump;
          //     console.log('=====>', questionJump, answerIndex, item)
          // 		const targetIndex = this.exam.findIndex((val) => questionJump.locationKey == val.locationKey);
          // 		this.handleHideTopicCallback(index, targetIndex);
          // 	});
          // }
        }
      });
    },
    // 题跳题逻辑
    handleScrollExam({ questionJump }, index) {
      if (index === this.endTopicIndex) {
        if (!questionJump || questionJump.locationKey != -1) {
          this.endTopicIndex = 999999999;
        }
      }

      if (questionJump) {
        if (questionJump.locationKey == -1) {
          this.endTopicIndex = index;
        }
      }

      this.handleHideTopic();
    },
    //opitonsList 排序逻辑
    updateOptions(list, examIndex) {
      this.$set(this.exam[examIndex], 'optionList', list)
    },
    // 判断题是否显示当前题
    checkShowMethods(item, index, isClearValue) {
      if (item.hideTopic) return false
      // 如果没有配置显示条件，就默认显示
      if (!item.settingShow) return true
      if (!(item.settingShow || []).length) return true

      // 判断显示条件
      const locationKeyArr = item.settingShow.map(val => val.locationKey);
      const trainTopicArr = this.exam.filter(val => locationKeyArr.includes(val.locationKey))
      for (let i = 0; i < trainTopicArr.length; i++) {
        const topic = trainTopicArr[i];
        const targetTopic = item.settingShow.find(val => val.locationKey == topic.locationKey)
        if (targetTopic) {
          if (topic.questionType == 11 && (topic.value || topic.value === 0)) {
            const locationKey = topic.optionList[topic.value].key;
            if (targetTopic.optionId.includes(locationKey)) return true
          } else if (topic.questionType == 12 && (topic.value || []).length) {
            for (let j = 0; j < topic.value.length; j++) {
              const locationKey = topic.optionList[topic.value[j]].key;
              if (targetTopic.optionId.includes(locationKey)) return true
            }
          }
        }
      }
      // 如果需要清空选项的值，隐藏的时候，就清一下
      if (isClearValue) {
        if (Object.prototype.toString.call(item.value) === '[object Array]') {
          if (item.value.length) {
            item.value = []
          }
        } else if (item.value || item.value === 0) {
          item.value = ''
        }
      }
      return false
    },
    isNotEmpty(value) {
      if (value === null) return false
      if (value === undefined) return false
      if (Array.isArray(value) && value.length === 0) {
          // 是空数组
          return false;
      } else if (typeof value === 'string' && value === '') {
          // 是空字符串
          return false;
      }
      return true;
    },
     handlerSumBlur() {
      if (this.exam.findIndex(item => item.questionType == '102') != -1) {
        let sumList = this.exam.filter(item => item.questionType == '102')
        sumList.forEach(item => {
          let sum = 0
          let startIndex = this.exam.findIndex(item1 => item1.locationKey == item.startKey)
          let endIndex = this.exam.findIndex(item1 => item1.locationKey == item.endKey)
          for (let i = startIndex; i <= endIndex; i++) {
            if(!item.chooseQuestionType) {
              sum += this.validatorCommentCount(this.exam[i].value || '')
            } else {
              const chooseQuestionType = item.chooseQuestionType.join(',')
              if (!chooseQuestionType.includes(this.exam[i].questionType)) continue;
              sum += this.validatorCount(this.exam[i])
            }
          }
          item.value = sum
        })
      }
    },
    validatorCommentCount(value) {
      let counts = value.split('@%_@%')
      return counts.reduce((acc, currentValue) => {
        let number = Number(currentValue);
        return acc + (isNaN(number) ? 0 : number);
      }, 0)
    },
    validatorCount(topic) {
      let value = topic.value;
      if (!this.isNotEmpty(value)) return 0
      if (topic.questionType == 11) {
        value = topic.optionList[value].name;
      }
      if (topic.questionType == 12) {
        value = value.map(item => topic.optionList[item].name).join('@%_@%');
      }
      if (!value) return 0
      let counts = value.split('@%_@%')
      return counts.reduce((acc, currentValue) => {
        let number = Number(currentValue);
        return acc + (isNaN(number) ? 0 : number);
      }, 0)
    },
  },
};
</script>

<style scoped>
.placeholder {
  height: 20px;
}
</style>
