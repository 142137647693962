<template>
    <div class="i-surveys-result-list">
        <div class="i-surveys-result-list-main">
            <div class="main_header van-hairline--bottom">
              <div class="main_header_head">
                <div class="title">{{ surveysDetail.title }}</div>
                <div class="count" v-if="surveysDetail.surveyTask && surveysDetail.surveyTask.total != -1&&showNumber == 'true'">{{ surveysDetail.surveyTask.completeNumber || 0 }}/{{ surveysDetail.surveyTask.total || 0 }}</div>
              </div>
              <van-tabs
                v-model="tabActive"
                color="#1F2329"
                title-inactive-color="#8F959E"
                title-active-color="#1F2329"
              >
                <van-tab title="全部" name=""></van-tab>
                <van-tab title="已通过" name="2"></van-tab>
                <van-tab title="已完成" name="4"></van-tab>
                <van-tab title="被驳回" name="3"></van-tab>
                <van-tab title="审核中" name="1"></van-tab>
              </van-tabs>
            </div>
            <van-list
                v-if="loading || total"
                :value="loading"
                :finished="finished"
                @load="loadMore"
            >
                <list-item
                    v-for="item in list"
                    :key="item.id"
                    :item="item"
                    @click="clickItem"
                ></list-item>
            </van-list>

            <van-empty v-else :image="`${$config[$store.getters['user/getAppid']].fileBaseUrl}/empty.png`" description="暂无数据" />
        </div>
        <base-footer background-color="#fff" v-if="surveysDetail.surveyTask && (surveysDetail.surveyTask.completeNumber < surveysDetail.surveyTask.total)">
          <van-button class="van-button_c" :class="{'btn-mask': !taskUnlock}" type="primary" block @click="clickCreate">新建</van-button>
        </base-footer>
      <!-- 选择类型弹窗 -->
      <list-type-dialog ref="refListTypeDialog" @confirm="clickConfirmDialog" />
    </div>
</template>

<script>
import ListItem from './components/list-item.vue'
import listMixins from '@/mixins/list'
import ListTypeDialog from '../list/components/list-type-dialog.vue'
import { mapState, mapMutations } from 'vuex'
export default {
    mixins: [listMixins],
    components: { ListItem, ListTypeDialog },
    data: () => ({
      unMounted: true,
      tabActive: '',
      surveysDetail: {},
        selectParams: {
          reviewStatus: '',
          surveyType: 1,
          surveyId: '',
          isDeleted: 0,
          budgetId: '',
          budgetSettingId: '',
        }
    }),
    computed: {
      ...mapState("surveys", ["pageTitle", "surveysTitle"]),
      runStatus () {
        return this.$status.activity.runStatus[this.surveysDetail.activity?.activityStatus]
      },
      apiSelect () {
        return this.$api.surveys.asnwerPage
      },
      showNumber () {
        return this.$route.query.showNumber
      },
      taskUnlock() {
        return this.$route.query.taskUnlock == 'true' ? true : false
      }
    },
    watch: {
      tabActive (newVal, oldVal) {
        if (newVal != oldVal) {
          this.$set(this.selectParams, 'reviewStatus', newVal)
          this.getDataPage()
        }
      }
    },
    created () {
        this.$set(this.selectParams, 'budgetId', this.$route.query.budgetId)
        this.$set(this.selectParams, 'budgetSettingId', this.$route.query.budgetSettingId)
        this.$set(this.selectParams, 'surveyId', this.$route.query.surveyId)
        this.$set(this.params, 'limit', 9999)
        // this.SET_PAGE_TITLE(this.surveysTitle)
        this.SET_PAGE_TITLE('历史提交')
        this.$route.query.type && this.$set(this.selectParams, 'surveyType', this.$route.query.type)
        this.getSurveysDetail()
    },
    methods: {
        ...mapMutations('surveys', ['SET_PAGE_TITLE', 'SET_SURVEYS_TITLE']),
        getSurveysDetail () {
          this.$api.surveys.surveysDetail(this.$route.query.surveyId, {
            budgetId: this.$route.query.budgetId,
            budgetSettingId: this.$route.query.budgetSettingId
          }).then(res => {
            if (!res.code) {
              this.surveysDetail = res.data;
              this.getDataPage();
            }
          })
        },
        clickCreate () {
          // 未解锁显示弹窗
          if (!this.taskUnlock) {
            this.$dialog
              .alert({
                title: "提示",
                message: "当前活动尚未解锁，\n请先完成前面的活动类型",
                cancelButtonText: "知道了",
                confirmButtonColor: '#000000'
              })
              .then(() => {})
            return
          }

          if (this.surveysDetail.activity?.activityStatus == 2) {
            this.$api.activity.checkTaskCount({
              doctorTaskId: this.surveysDetail.surveyTask.id,
              taskType: 2
            }).then(res => {
              if (!res.code) {
                if (res.data) {
                  let message = `您有暂存未提交的内容已占用活动次数，请前往历史列表对应编辑并提交`
                  if (res.data == 2) message = `活动任务数量已达成`
                  this.$dialog.alert({
                    className: 'custom_dialog_box',
                    theme: 'round-button',
                    title: '提示',
                    message,
                    confirmButtonText: '确定',
                    confirmButtonColor: '#F4CD13',
                  })
                } else {
                  this.checkActivityStatus()
                }
              }
            })
            // const { completeNumber = 0, total = 0 } = this.surveysDetail.surveyTask
            // if (completeNumber >= total) {
            //     this.$toast(`暂无可参与次数，请查看进度`);
            // } else {
            //   this.checkActivityStatus()
            // }
          } else {
            this.$toast(`活动${this.runStatus}`);
          }
        },
        // 判断活动课题是否可用
        checkActivityStatus () {
          this.$api.activity.checkActivityStatus({
            type: 2,
            doctorTaskId: this.surveysDetail.surveyTask.id
          }).then(res => {
            if (!res.code) {
              if (res.data === 1) {
                this.$refs.refListTypeDialog.init(this.surveysDetail)
              } else {
                this.$toast('活动未激活')
              }
            }
          })
        },
        // 点击确认 -- 弹窗
        clickConfirmDialog(val) {
          this.$router.push({
            path: `/i-surveys/form/${val.selectId}`,
            query: {
              activityId: this.$route.query.activityId,
              ...val
            }
          })
        },
        clickItem ({ value }) {
            let formType = ''
            switch (value.reviewStatus) {
                case 0:
                case 3:
                    formType = 'edit'
                    break;
                default: 
                    formType = 'preview'
            }

            this.$router.push({
                path: `/i-surveys/form/${ value.id }`,
                query: {
                    formType,
                    surveyId: value.surveyId,
                    type: value.type || this.$route.query.type,
                    activityId: this.$route.query.activityId
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
    
</style>